<script>
  import { Link } from "svelte-routing";
</script>

<style>
  .login-box {
    min-width: 300px;
  }
  button {
    display: block;
    width: 100%;
  }
</style>

<div class="center-container">
  <h3>Login</h3>
  <div class="login-box">
    <div class="row">
      <form class="col s12">
        <div class="row">
          <div class="input-field col s12">
            <input id="phoneNo" type="text" class="validate" />
            <label class="active" for="phoneNo">No Handphone</label>
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12">
            <input id="password" type="password" class="validate" />
            <label class="active" for="password">Password</label>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <button class="btn m-b-20">Login</button>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <Link to="/forgot-password">
              <a href=""  class="float-right">Lupa Password?</a>
            </Link>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <Link to="/register">
              <a href=""  class="float-right">Daftar Baru</a>
            </Link>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
